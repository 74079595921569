<template>
  <div>
    <el-input
      style="width:217px"
      placeholder="请输入关键词搜索"
      v-model="search"
      clearable
    ></el-input>
    <el-button type="primary" style="margin-left:20px" @click="remoteMethod"
      >搜 索</el-button
    >
    <el-button type="primary" style="margin-left:20px" @click="add = true"
      >新 增</el-button
    >
    <el-upload
      class="uploadDemo"
      action="http://farmerstest.hengshunits.com/admin/v1/seller_excel_import"
      accept=".xlsx"
      :show-file-list="false"
      :http-request="uploadXlsxFile1"
      :before-upload="uploadXlsx"
    >
      <el-button type="primary">导 入</el-button>
    </el-upload>

    <el-table
      :data="tableData.data"
      border
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      style="width: 100%;margin-top:20px"
    >
      <el-table-column prop="id" label="编号" width="100"> </el-table-column>
      <el-table-column prop="name" label="姓名" width="100"> </el-table-column>
      <el-table-column prop="tel" label="手机号"> </el-table-column>
      <el-table-column prop="sex" label="性别" width="100"> </el-table-column>
      <el-table-column prop="native_place" label="籍贯"> </el-table-column>
      <el-table-column prop="" label="年龄" width="100"> </el-table-column>
      <el-table-column prop="birth_date" label="出生年月" width="100">
      </el-table-column>
      <el-table-column prop="national_identification_number" label="身份证号">
      </el-table-column>
      <el-table-column label="免冠照">
        <template slot-scope="scope">
          <img
            :src="scope.row.according_the_img"
            style="width:100px;height:50px"
            alt=""
            @click="$refs.img.previewImage(scope.row.according_the_img)"
          />
        </template>
      </el-table-column>
      <el-table-column label="培训证书">
        <template slot-scope="scope">
          <img
            :src="scope.row.training_certificate_img"
            style="width:100px;height:50px"
            alt=""
            @click="$refs.img.previewImage(scope.row.training_certificate_img)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="address" label="经营信息">
        <template>
          <el-link type="primary">查 看</el-link>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="primary" @click="copyreader(scope.row)"
            >编 辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      @close="closeappend"
      :visible.sync="add"
      width="40%"
      center
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <div style="width:100%;display: flex;  justify-content: space-evenly;">
          <div>
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入姓名"
                style="width:217px"
                maxlength="5"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="tel">
              <el-input
                v-model="ruleForm.tel"
                placeholder="请输入手机号"
                style="width:217px"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="身份证号"
              prop="national_identification_number"
            >
              <el-input
                v-model="ruleForm.national_identification_number"
                placeholder="请输入身份证号"
                style="width:217px"
                maxlength="18"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="微信号" prop="wechat_id">
              <el-input
                v-model="ruleForm.wechat_id"
                placeholder="请输入微信号"
                style="width:217px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="出生日期" prop="birth_date">
              <el-date-picker
                v-model="ruleForm.birth_date"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="免冠照" prop="according_the_img">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile1"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.according_the_img"
                  :src="ruleForm.according_the_img"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="健康证" prop="health_certificate_img">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.health_certificate_img"
                  :src="ruleForm.health_certificate_img"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="省份" prop="province_id">
              <el-select
                v-model="ruleForm.province_id"
                placeholder="请选择省份"
              >
                <el-option
                  v-for="item in Form.provinceid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="provinceidCity"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="城市" prop="city_id">
              <el-select v-model="ruleForm.city_id" placeholder="请选择城市">
                <el-option
                  v-for="item in Form.cityid.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="cityidArea"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="区县" prop="area_id">
              <el-select v-model="ruleForm.area_id" placeholder="请选择区县">
                <el-option
                  v-for="item in Form.areaid.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证书名称" prop="training_certificate_title">
              <el-input
                v-model="ruleForm.training_certificate_title"
                placeholder="请输入证书名称"
                style="width:217px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="上传证书" prop="training_certificate_img">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile3"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.training_certificate_img"
                  :src="ruleForm.training_certificate_img"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio v-model="ruleForm.sex" label="1">男</el-radio>
              <el-radio v-model="ruleForm.sex" label="2">女</el-radio>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提 交</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import { GETsearch, POSTsubmit } from '../../../api/manage'
import { provinceCityArea, getcity } from '../../../api/layout'
import { upload, uploadXlsx } from '../../../utile/upload'
export default {
  name: 'addManage',
  data () {
    return {
      tableData: '', // 表格数据
      limit: '',
      page: '',
      search: '',
      loading: true,
      add: false,
      title: '新增会员',
      id: '', // 编辑按钮的id
      Form: {
        provinceid: '', // 省数据
        cityid: '', // 市数据
        areaid: '' // 区数据
      },
      ruleForm: {
        name: '', // 名称
        tel: '', // 电话
        sex: '1', // 性别：1为男，2为女
        national_identification_number: '', // 身份证号
        wechat_id: '', // 微信号
        province_id: '', // 省
        city_id: '', // 市
        area_id: '', // 区
        birth_date: '', // 出生日期
        according_the_img: '', // 免冠证
        health_certificate_img: '', // 健康证
        training_certificate_img: '', // 培训证书图片
        training_certificate_title: '' // 培训证书名称
      },
      rules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入姓名'))
              } else {
                const ref = /[\u4e00-\u9fa5]/gm
                if (ref.test(this.ruleForm.name)) {
                  callback()
                } else {
                  callback(new Error('请输入正确的姓名'))
                }
              }
            },
            trigger: 'blur',
            required: true
          }
        ],
        tel: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入手机号码'))
              } else {
                const ref = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
                if (ref.test(this.ruleForm.tel)) {
                  callback()
                } else {
                  callback(new Error('请输入正确的手机号码'))
                }
              }
            },
            trigger: 'blur',
            required: true
          }
        ],
        national_identification_number: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入身份证号码'))
              } else {
                const ref = /^[1-8][1-7]\d{4}(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/
                if (ref.test(this.ruleForm.national_identification_number)) {
                  callback()
                } else {
                  callback(new Error('请输入正确的身份证号码'))
                }
              }
            },
            trigger: 'blur',
            required: true
          }
        ],
        wechat_id: [
          { required: true, message: '请输入微信号', trigger: 'blur' }
        ],
        province_id: [
          { required: true, message: '请选择省份', trigger: 'blur' }
        ],
        city_id: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        area_id: [{ required: true, message: '请选择区县', trigger: 'blur' }],
        birth_date: [
          { required: true, message: '请输入出生日期', trigger: 'blur' }
        ],
        according_the_img: [
          { required: true, message: '请上传免冠证照', trigger: 'blur' }
        ],
        health_certificate_img: [
          { required: true, message: '请上传健康证照', trigger: 'blur' }
        ],
        training_certificate_img: [
          { required: true, message: '请上传培训证书证照', trigger: 'blur' }
        ],
        training_certificate_title: [
          { required: true, message: '请输入培训证书名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 表格数据
    GET_search ({ page = '', limit = '', search = '' } = {}) {
      GETsearch({ page, limit, search }).then(res => {
        res.data.data.forEach(item => {
          if (item.sex * 1 === 1) {
            item.sex = '男'
          } else {
            item.sex = '女'
          }
        })
        this.tableData = res.data
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.GET_search({ limit: val })
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.GET_search({ page: val })
    },
    // 头部搜索框
    remoteMethod () {
      this.GET_search({ search: this.search })
    },
    GET_provinceCityArea () {
      provinceCityArea().then(res => {
        this.Form.provinceid = res.data
      })
    },
    // 选择啦省份
    provinceidCity () {
      this.ruleForm.city_id = ''
      this.ruleForm.area_id = ''
      getcity(this.ruleForm.province_id).then(res => {
        this.Form.cityid = res.data
      })
    },
    // 选择啦城市
    cityidArea () {
      this.ruleForm.area_id = ''
      getcity(this.ruleForm.city_id).then(res => {
        this.Form.areaid = res.data
      })
    },
    // 导入文件
    uploadXlsx (file) {
      const isShow = file.name.split('.')
      const status = isShow[isShow.length - 1] === 'xlsx'
      if (!status) {
        this.$message.error('只能上传xlsx的格式的表格!')
      }
      return status
    },
    uploadXlsxFile1 (param) {
      uploadXlsx(param)
        .then(res => {
          if (res.data.code === 200) {
            this.$message.success(`${res.data.data}`)
            this.GET_search({ page: this.page, limit: this.limit })
          } else {
            this.$message.error(`${res.data.msg}`)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径
    uploadSectionFile1 (param) {
      // this.ruleForm.according_the_img = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.according_the_img = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile2 (param) {
      // this.ruleForm.health_certificate_img = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.health_certificate_img = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile3 (param) {
      // this.ruleForm.training_certificate_img = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.training_certificate_img = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 弹框取消
    closeappend () {
      this.title = '新增会员'
      this.ruleForm = {
        name: '', // 名称
        tel: '', // 电话
        sex: '1', // 性别：1为男，2为女
        national_identification_number: '', // 身份证号
        wechat_id: '', // 微信号
        province_id: '', // 省
        city_id: '', // 市
        area_id: '', // 区
        birth_date: '', // 出生日期
        according_the_img: '', // 免冠证
        health_certificate_img: '', // 健康证
        training_certificate_img: '', // 培训证书图片
        training_certificate_title: '' // 培训证书名称
      }
    },
    // 编辑按钮
    async copyreader (row) {
      console.log(row)
      await this.GET_provinceCityArea()
      this.add = true
      this.id = row.id
      this.title = '编辑信息'
      this.ruleForm.name = row.name
      this.ruleForm.sex = `${row.sex === '男' ? '1' : '2'}`
      this.ruleForm.tel = row.tel2
      this.ruleForm.national_identification_number =
        row.national_identification_number2
      this.ruleForm.wechat_id = row.wechat_id
      this.ruleForm.birth_date = row.birth_date
      this.ruleForm.according_the_img = row.according_the_img
      this.ruleForm.health_certificate_img = row.health_certificate_img
      this.ruleForm.training_certificate_img = row.training_certificate_img
      this.ruleForm.training_certificate_title = row.training_certificate_title
      this.ruleForm.province_id = row.province_id
      await this.provinceidCity()
      this.ruleForm.city_id = row.city_id
      await this.cityidArea()
      this.ruleForm.area_id = row.area_id
    },
    // 提交按钮
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = this.ruleForm
          if (this.title === '新增会员') {
            POSTsubmit(data).then(res => {
              if (res.code === 200) {
                this.add = false
                this.$message.success(`${res.data}`)
                this.ruleForm = {
                  name: '', // 名称
                  tel: '', // 电话
                  sex: '1', // 性别：1为男，2为女
                  national_identification_number: '', // 身份证号
                  wechat_id: '', // 微信号
                  province_id: '', // 省
                  city_id: '', // 市
                  area_id: '', // 区
                  birth_date: '', // 出生日期
                  according_the_img: '', // 免冠证
                  health_certificate_img: '', // 健康证
                  training_certificate_img: '', // 培训证书图片
                  training_certificate_title: '' // 培训证书名称
                }
              }
            })
          } else {
            data.id = this.id
            POSTsubmit(data).then(res => {
              if (res.code === 200) {
                this.add = false
                this.$message.success(`${res.data}`)
                this.ruleForm = {
                  name: '', // 名称
                  tel: '', // 电话
                  sex: '1', // 性别：1为男，2为女
                  national_identification_number: '', // 身份证号
                  wechat_id: '', // 微信号
                  province_id: '', // 省
                  city_id: '', // 市
                  area_id: '', // 区
                  birth_date: '', // 出生日期
                  according_the_img: '', // 免冠证
                  health_certificate_img: '', // 健康证
                  training_certificate_img: '', // 培训证书图片
                  training_certificate_title: '' // 培训证书名称
                }
                this.id = ''
              }
            })
          }
          this.GET_search({ page: this.page, limit: this.limit })
        } else {
          return false
        }
      })
    }
  },
  mounted () {
    this.GET_search()
    this.GET_provinceCityArea()
  }
}
</script>

<style lang="less" scoped>
/deep/.el-upload-list {
  display: none;
}
.footers {
  text-align: right;
  margin-top: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader /deep/ .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 120px;
  line-height: 120px;

  text-align: center;
}
.avatar {
  width: 100px;
  height: 120px;
  display: block;
}
.uploadDemo {
  display: inline-block;
  margin-left: 20px;
  border: none;
}
.uploadDemo /deep/.el-upload {
  border: none;
}
</style>
